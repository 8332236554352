@font-face {
    font-family: "w95";
    font-style: normal;
    font-weight: normal;
    src: local("w95"), local("w952"), url("fonts/w95fa.woff") format("woff"), url("fonts/w95fa.woff2") format("woff2");
}

@font-face {
    font-family: 'Rounded Mplus 1c';
    src: url('fonts/RoundedMplus1c-Black.woff2') format('woff2'),
        url('fonts/RoundedMplus1c-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rounded Mplus 1c';
    src: url('fonts/RoundedMplus1c-Regular.woff2') format('woff2'),
        url('fonts/RoundedMplus1c-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
  
html, body {
    width: 100%;
    height: 100%;
    min-width: 375px;
    min-height: 600px;
    background: #ffcdaa;
}
  
body {
    margin: 0;
    font-family: 'Rounded Mplus 1c', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
}

/* * {
    font-family: 'Rounded Mplus 1c', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
} */

/* * , *:before, *:after { 
    box-sizing:border-box; 
    -moz-box-sizing:border-box; 
    -webkit-box-sizing:border-box; 
    -ms-box-sizing:border-box;
} */

.w95-font, .w95-font > * {
    font-family: 'w95', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: content-box; 
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
  
#root {
    width: 100%;
    height: 100%;
}

.aws-btn {
    --button-default-height: 48px;
    --button-default-font-size: 14px;
    --button-default-border-radius: 6px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 5px;
    --button-hover-pressure: 2;
    --transform-speed: .185s;
    --button-primary-color: #00b8c4;
    --button-primary-color-dark: #d0b400;
    --button-primary-color-light: #ffe11d;
    --button-primary-color-hover: #00a0ab;
    --button-primary-border: 1px solid #ffe11d;
    --button-secondary-color: #dbb492;
    --button-secondary-color-dark: #5e462e;
    --button-secondary-color-light: #000000;
    --button-secondary-color-hover: #ffcdaa;
    --button-secondary-border: 3px solid #000000;
    --button-anchor-color: #293b83;
    --button-anchor-color-dark: #772250;
    --button-anchor-color-light: #b33377;
    --button-anchor-color-hover: #293b83;
    --button-anchor-border: 1px solid #9f2d6a;
}

img.img-modal {
    width: 100%;
}
p.img-modal {
    text-align: center;
    margin-bottom: 0px;
}

@media screen and (max-width: 640px) {
    .ReactModal__Content {
        height: auto !important;
        width: 80% !important;
    }
}

.bm-menu, .bm-menu a, .bm-menu a:focus {
    outline: none;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 400px;
    height: 400px;
    max-width: 75%;
}

.loader img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}

.loader h2 {
    font-weight: 900;
    margin-bottom: -40px;
}

/* .loader.inner {
    background: yellow;
    display: none;
    top: 0px;
    transform: translateX(-50%);
    margin-top: 100px;
} */

/* @media screen and (max-width: 600px) {
    .loader.inner {
        display: none;
    }
} */